import React from "react"

import Layout from "../components/layout"
import ContactUs from "../components/ContactUs"
import ImgTouchstone from "../components/img/ImgTouchstone"
import ImgTouchstoneMgmt from "../components/img/ImgTouchstoneMgmt"
import PageHeader from "../components/PageHeader"

const Touchstone = () => (
  <Layout>
    <PageHeader
      title="Touchstone"
      subtitle="A tool to test your Physical &amp; Virtual infrastructure from a single platform."
    />
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mobile-margin-top">
            <ImgTouchstone />
          </div>
          <div className="col-lg-6 mt-4">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2">What is Touchstone?</h2>
            </div>
            <p>
                Touchstone, developed by VoerEir, stands as a remarkable offering, 
                serving as an invaluable tool for NFVI benchmarking, certification, and validation.
            </p>
            <p>
                As a comprehensive Test Suite manager, Touchstone comes equipped with an extensive array
                of automated test cases and pre-packaged tools. 
                These resources empower users to seamlessly evaluate the performance, functionality, robustness, 
                and stability of their cloud environments. 
            </p>
            <p>
            Touchstone facilitates testing of both <strong>OpenStack</strong> and <strong>Kubernetes</strong> infrastructures, 
            empowering users to comprehensively evaluate and optimize their cloud environments.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Touchstone Features</h2>
            </div>
            <p className="paragraph-3">
              {/*Touchstone offers facilities to benchmark multiple cloud
              environments spanning multiple data centers. It provides support
              for benchmarking the environments using an advanced test engine
              developed by VoerEir, and many popular open source test engines
              like OpenStack Rally, OPNFV Yardstick and Google
              PerfkitBenchmarker among many others. Touchstone provides a wide
              array of features for environment benchmarking, report management,
              and Enhanced Platform Aware (EPA) test suites. Touchstone is a
              touchstone in cloud environment benchmarking, certification and
validation.*/}
            Touchstone revolutionizes cloud environment benchmarking, certification, and validation by 
            offering comprehensive facilities that span multiple data centers and cloud environments. 
            Distinguished by an advanced test engine developed by VoerEir, specifically tailored for Kubernetes and OpenStack environments, 
            Touchstone provides unparalleled support for benchmarking.
            </p>
            <p className="paragraph-3">
            Notably, Touchstone boasts the capability to leverage various renowned open source test engines, such as Kubernetes Kube-Hunter, Kubernetes Litmus, 
            OpenStack Rally, OpenStack Tempest, and many others. This versatility ensures users have access to a wide range of options for
             benchmarking their environments.
            </p>
            <p className="paragraph-3">
            With an extensive range of features for environment benchmarking, report management, and Enhanced Platform Aware (EPA) test suites, Touchstone exemplifies excellence in cloud environment benchmarking. It truly serves as the touchstone for organizations seeking to achieve exceptional standards in benchmarking, certification, and validation.
            </p>
            <p className="paragraph-3">
              Touchstone out of the box comes with many features:
            </p>
            <ul className="theme-ui">
              <li>
                <b>Parallel benchmark:</b> We also provide support for the
                benchmarking of legacy versions of both Kubernetes and OpenStack platforms.
              </li>
              <li>
                <b>Types of Testing:</b> Touchstone supports a wide range of
                environment benchmarks. Some of the benchmarks that can be
                performed using Touchstone are performance testing, robustness
                testing, API testing, Chaos testing and functional testing.
              </li>
              <li>
                <b>Environment Monitoring:</b> Touchstone supports monitoring the resource and power consumption of any Kubernetes environment while it is being benchmarked, to enable correlation and comparison of various K8s platforms. Touchstone also supports stand alone monitoring, while the environment is loaded with proprietary network functions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-padding-top section-margin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ImgTouchstoneMgmt />
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Easy Management</h2>
            </div>
            <p className="paragraph-3">
            Touchstone includes a user-friendly Management Portal that streamlines Touchstone management, simplifying the process of configuring Touchstone according to users' specific requirements.
            </p>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default Touchstone
